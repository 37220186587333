<template>
  <div>
    <div class="vs-row height">
      <img class="w-full h-full" :src="activity.banner" alt="">
    </div>
    <div class="mx-7 m-auto">
      <div class="divide-y divide-gray-300 mx-3">
        <div v-if="$route.params.lang === 'en'" class="vs-row mt-5 pb-16">
          <div class="vs-col xl:w-1/2 lg:w-1/2 md:w-1/3 w-full" >
            <p class="text-lg">{{activity.title}}</p>
          </div>
          <div class="vs-col xl:w-1/2 lg:w-1/2 md:w-2/3 w-full flex justify-end md:my-0 my-3">
            <vs-button class="btn" color="#3a3a3a" text-color="#fff" icon="favorite">{{$t('Save')}}</vs-button>
            <vs-button class="btn ml-3" color="#00DCDC" text-color="#fff" icon="reply">{{$t('Share')}}</vs-button>
          </div>
        </div>
        <div v-if="$route.params.lang === 'ar'" class="vs-row mt-5 pb-16">
          <div class="vs-col xl:w-1/2 lg:w-1/2 md:w-2/3 w-full flex justify-end md:my-0 my-3">
            <vs-button class="btn" color="#3a3a3a" text-color="#fff" icon="favorite">{{$t('Save')}}</vs-button>
            <vs-button class="btn mr-3" color="#00DCDC" text-color="#fff" icon="reply">{{$t('Share')}}</vs-button>
          </div>
          <div class="vs-col xl:w-1/2 lg:w-1/2 md:w-1/3 w-full" >
            <p class="text-lg">{{activity.title}}</p>
          </div>
        </div>
        <div class="activity-detail vs-row pt-7">
          <div class="vs-col xl:w-2/3 md:w-2/3 w-full divide-y divide-gray-300 md:pr-16 text-lg">
            <div class="vs-row mb-5">
              <div v-if="$route.params.lang === 'en'" class="vs-row mt-2 pb-7">
                <div class="vs-col xl:w-1/2 lg:w-1/2 w-full divide-x divide-gray-500">
                  <span class="pr-2">At {{activity.location[0].branch.area_name}}</span>
                  <span class="px-2">{{activity.age_from}}-{{activity.age_to}} Years</span>
                  <span class="pl-2">{{interestsDic[activity.interests_id]}}</span>
                </div>
                <div class="vs-col xl:w-1/2 lg:w-1/2 w-full flex">
                  <img class="w-7" src="@/assets/images/cash.svg" alt=""><span class="text-orange ml-2">{{activity.location[0].price}} KWD</span>
                </div>    
              </div>
              <div v-if="$route.params.lang === 'ar'" class="vs-row mt-2 pb-7">
                <div class="vs-col xl:w-1/2 lg:w-1/2 w-full flex">
                  <img class="w-7" src="@/assets/images/cash.svg" alt=""><span class="text-orange mr-2">{{activity.location[0].price}} KWD</span>
                </div> 
                <div class="vs-col xl:w-1/2 lg:w-1/2 w-full divide-x divide-gray-500">
                  <span class="pr-2">At {{activity.location[0].branch.area_name}}</span>
                  <span class="px-2">{{activity.age_from}}-{{activity.age_to}} Years</span>
                  <span class="pl-2">{{interestsDic[activity.interests_id]}}</span>
                </div>   
              </div>
              <div class="vs-row text-gray-600">
                <div v-html="activity.description"></div>
              </div>
            </div>
            <div class="vs-row pt-5 lg:mb-32 mb-56">
              <div class="vs-col w-3/12 flex justify-center">
                <img class="w-28 self-center" :src="activity.club_details.logo" alt="">
              </div>
              <div class="vs-col lg:w-7/12 w-10/12">
                <div class="self-center text-xl">
                  <p class="text-blue font-medium">{{activity.club_details.name}}</p>
                </div>
              </div>
              <div class="vs-col lg:w-2/12 w-2/12 flex justify-center">
                <vs-icon class="self-center" size="medium" color="primary" icon="share"></vs-icon>
              </div>
            </div>
            <div class="vs-row pt-5 pb-5">
              <p>{{$t('Schedule')}}</p>
              <p v-html="activity.schedule"></p>
            </div>
            <div class="vs-row vs-col w-full pt-5 pb-7">
              <p class="mb-4">{{$t('Gallery')}}</p>
              <vueper-slides
                ref="vueperslides1"
                :touchable="false"
                fade
                :autoplay="false"
                :bullets="false"
                @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
                fixed-height="400px">
                <vueper-slide
                  v-for="(slide, i) in activity.gallery"
                  :key="i"
                  :image="slide.photo_name">
                </vueper-slide>
              </vueper-slides>
              <vueper-slides
                class="no-shadow thumbnails"
                ref="vueperslides2"
                @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
                :visible-slides="slides.length"
                fixed-height="75px"
                :bullets="false"
                :touchable="false"
                :gap="2.5"
                :arrows="false">
                <vueper-slide
                  v-for="(slide, i) in activity.gallery"
                  :key="i"
                  :image="slide.photo_name"
                  @click.native="$refs.vueperslides2.goToSlide(i)">
                </vueper-slide>
              </vueper-slides>
            </div>
          </div>
          <div class="vs-col xl:w-1/3 md:w-1/3 w-full">
            <vs-card>
              <div class="px-3 text-lg">
                <div class="vs-row flex justify-end">
                  <img class="w-11" style="margin-top: -10px;" src="@/assets/images/discountpadge.svg" alt="">
                  <span v-if="$route.params.lang === 'en'" class="absolute pr-2 font-bold text-white text-sm">25%</span>
                  <span v-if="$route.params.lang === 'ar'" class="absolute pl-2 font-bold text-white text-sm">25%</span>
                </div>
                <div class="flex">
                  <span class="self-center time mr-1 text-blue">{{$t('Special Order')}}</span>
                  <vs-icon class="material-icons-outlined self-center" color="warning" icon="info"></vs-icon>
                </div>
                <p class="mt-1">{{activity.title}}</p>
                <div class="flex text-sm">
                  <vs-icon class="self-center" color="#00DCDC" icon="location_on"></vs-icon>
                  <div class="divide-x divide-gray-300 text-gray-500 self-center text-base">
                    <span class="pr-2">{{activity.location[0].branch.area_name}}</span>
                    <span class="px-2">{{activity.age_from}}-{{activity.age_to}} Years</span>
                    <span class="pl-2">{{interestsDic[activity.interests_id]}}</span>
                  </div>
                </div>
                <div class="mt-1">
                  <span class="text-blue">{{$t('Price: ')}}</span><span class="text-orange">{{activity.location[0].price}} {{$t('KWD')}}</span>
                </div>
                <div class="vs-row my-5">
                  <vs-button @click="openBookModal()" class="btn w-full" color="warning" text-color="#fff">{{$t('Book Now')}}</vs-button>
                </div>
              </div>
            </vs-card>
          </div>
          <!-- <div class="vs-row pt-5 pb-7">
            <p class="text-lg mb-4">Related</p>
            <div>
              <swiper class="swiper" :options="swiperOption">
                <swiper-slide v-for="(o, index) in 9" :key="o" :offset="index > 0 ? 2 : 0">
                  <el-card class="text-left w-96" :body-style="{ padding: '0px' }">
                    <div class="cardBody">
                      <span class="material-icons btn">favorite</span>
                      <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image">
                    </div>
                    <div class="footer" style="padding: 14px;">
                      <span>The Hill-top Party in English</span>
                      <div class="mt-1">
                        <i class="el-icon-location text-blue"></i>
                        <span class="time mx-1 text-sm text-gray-400">At Home | 2-12 Years | Books</span>
                        <div class="vs-row mx-1 bottom flex">
                          <div class="vs-col w-3/4 self-center">
                            <span class="text-blue">Price: </span><span class="text-orange">2.500 KWD</span>
                          </div>
                          <div class="vs-col w-1/4 flex justify-end">
                            <vs-icon icon="add_circle" color="primary" size="30px"></vs-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </el-card>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <vs-prompt
      @close="closeModal"
      :active.sync="bookModal"
      title="Book Now"
      :buttons-hidden="true">
      <VuePerfectScrollbar :is="'div'" class="scroll-area" :settings="settings">
        <form>
          <vs-collapse accordion>
            <vs-collapse-item>
              <div slot="header">
                Select Location
              </div>
              <ul class="leftx">
                <li class="flex justify-start border-b-2 p-3 border-gray-200" :key="location.index" v-for="location in activity.location">
                  <vs-radio v-model="book.location" :vs-value="location">{{location.branch.branch_name}}</vs-radio>
                </li>
              </ul>
            </vs-collapse-item>
            <vs-collapse-item>
              <div slot="header">
                Select Date
              </div>
              <datepicker v-if="book.location" v-model="book.date" :disabledDates="disabledDates" class="mt-4 mb-4" :inline="true"></datepicker>
            </vs-collapse-item>
            <vs-collapse-item>
              <div slot="header">
                Select Time
              </div>
              <div class="flex justify-start border-b-2 p-3 border-gray-200">
                <vs-radio v-if="book.location && book.date" v-model="book.time" :vs-value="book.location.location_session[0].from_time - book.location.location_session[0].to_time">{{book.location.location_session[0].from_time}} - {{book.location.location_session[0].to_time}}</vs-radio>
              </div>
              <h4 ></h4>
            </vs-collapse-item>
            <vs-collapse-item>
              <div slot="header">
                Select Your Child
              </div>
              <ul class="leftx">
                <li class="flex justify-start border-b-2 p-3 border-gray-200" :key="child.index" v-for="child in activity.child">
                  <vs-checkbox v-model="book.child" :vs-value="child">{{child.child_name}}</vs-checkbox>
                </li>
              </ul>
            </vs-collapse-item>
          </vs-collapse>
        </form>
      </VuePerfectScrollbar>
      <div class="vs-row my-5">
        <vs-button @click="addToCart()" class="btn w-full" color="warning" text-color="#fff">Confirm</vs-button>
      </div>
    </vs-prompt>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import { VueperSlides, VueperSlide } from 'vueperslides'
// import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'vueperslides/dist/vueperslides.css'
import 'swiper/css/swiper.css'
import axios from '../axios.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  // Swiper, SwiperSlide,
  components: { VueperSlides, VueperSlide, VuePerfectScrollbar, Datepicker },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30
      },
      book: {
        child: []
      },
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      bookModal: false,
      interestsDic: {},
      // swiperOption: {
      //   slidesPerView: 5,
      //   spaceBetween: 10,
      //   slidesPerGroup: 3,
      //   loop: true,
      //   loopFillGroupWithBlank: true,
      //   pagination: {
      //     el: '.swiper-pagination',
      //     clickable: true
      //   },
      //   navigation: {
      //     nextEl: '.swiper-button-next',
      //     prevEl: '.swiper-button-prev'
      //   }
      // },
      slides: [
        { image: require('@/assets/images/footer.jpg') },
        { image: require('@/assets/images/footer.jpg') },
        { image: require('@/assets/images/footer.jpg') },
        { image: require('@/assets/images/footer.jpg') },
        { image: require('@/assets/images/footer.jpg') },
        { image: require('@/assets/images/footer.jpg') },
        { image: require('@/assets/images/footer.jpg') }
      ],
      activity: {
        club_details: {},
        location: [
          {
            branch: {}
          }
        ]
      },
      schedule: '',
      locationsDic: {},
      disabledDates: {}
    }
  },
  watch: {
    'book.location' (val) {
      if (val) {
        const from = new Date(val.from_date)
        const to = new Date(val.to_date)
        this.disabledDates = {
          to: from, // Disable all dates up to specific date
          from: to, // Disable all dates after specific date
        }
      }
    }
  },
  methods: {
    loadActivityDetail () {
      this.$vs.loading()
      axios.post(`${localStorage.getItem('access_token') && localStorage.getItem('loggedIn')? 'v1/user/getActivityById' : 'v1/getActivityByIdWeb'}`, {club_activity_id: this.$route.params.activityID}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.activity = response.data.payload
          this.activity.location.forEach(element => {
            this.locationsDic[element.id] = element
          })
          this.loadInterests()
        }
      })
    },
    closeModal () {
      this.bookModal = false
      this.book = {}
    },
    openBookModal () {
      this.bookModal = true
    },
    loadInterests () {
      this.$vs.loading()
      axios.get('v1/interestsList', {}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          response.data.payload.forEach(element => {
            this.interestsDic[element.interests_id] = element.name
          })
          this.$vs.loading.close()
        }
      })
    },
    addToCart () {
      let cart = JSON.parse(localStorage.getItem('cart'))
      this.book.time = this.book.location.location_session[0].from_time + '-' + this.book.location.location_session[0].to_time
      this.activity.book = this.book
      cart.push(this.activity)
      localStorage.setItem('cart', JSON.stringify(cart))
      this.bookModal = false
    }
  },
  created () {
    this.loadActivityDetail()
  }
}
</script>



<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  max-height: 75vh;

  &:not(.ps) {
    overflow-y: auto;
  }
}

.vueperslides__arrow svg {
  color: black !important;
}
.height {
  height: 500px;
}
.swiper {
  height: 400px;
  width: 100%;
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .swiper-button-prev, .swiper-button-next {
    background-color: #F89416;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  .swiper-button-prev:after, .swiper-button-next:after {
    color: #fff !important;
    font-size: small;
  }
}
</style>

<style scoped>
.image {
  width: 100%;
  height: 200px;
  display: block;
}
.cardBody {
  position: relative;
  width: 100%;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardBody .btn {
  color: gray;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 2%;
  right: 3%;
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.btn {
  padding: 7px 20px;
  border-radius: 2px;
}
.thumbnails {
  margin: auto;
  max-width: 100%;
  margin-top: 10px;
}
.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 2px solid #fff;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}
.thumbnails .vueperslide--active {
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-color: #00DCDC;
}
</style>